import { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Landing from "./pages/Landing";
import { ScrollTop } from "./style/js/globalFuns";
import { Page404 } from "./pages/404";
import AnimatedCursor from "react-animated-cursor"
import { useMediaQuery } from "react-responsive";


function App() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' });

  return (
    <Router>
      <Fragment>
        {/* <ScrollToTop /> */}

        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        {
          !isTabletOrMobile &&
          <AnimatedCursor
            innerSize={8}
            outerSize={20}
            color='172, 235, 47'
            outerAlpha={1}
            innerScale={0.7}
            outerScale={1.5}
            zIndex={9999}
          />
        }
      </Fragment>
      <ScrollTop />
    </Router>
  );
}

export default App;
