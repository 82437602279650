import React from 'react';

export const LinkedinIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="url(#linkedin)"
        d="M0 0H27.36V27.35H0z"
        transform="translate(0 .541)"
      ></path>

      <defs>
        <pattern
          id="linkedin"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.03125)" xlinkHref="#image0_1397_6"></use>
        </pattern>
        <image
          id="image0_1397_6"
          width="32"
          height="32"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIjSURBVHgB7VdLcptAEO0eRk4lK+3zMVSyj46gnCA+QCoyB0iEdvHKeOVUsnApOgA4ife5QZQb6ABxDcjfnVlbxbQbZGzhBZQLgbzwq4Jpehjm0TX9Q7gDpVSbhzYsH5FlWdFdJWZCeHTe10QOACWbR7B8pN+NQe+8efXCz82o6Zmnpqd/lTrqQo1Q6riT7jM98W6U/9XxJisVNIiExGF46iSyMKToQxzb0CTieEcA9DI2BCtAtq+AFaOUgLvntZMLakIhgW+jg/4zKdVT2br4PjrYhqYJEJJDgOnfayC3DksUEkCi3IbuwF56gCq2AIDNt4CJRBwzB1AHVu2Gsuil3R8/N/kgmOkDUrD1+aOf6oe/3OwdLcUfcXkZSbnW0wAd5FxCCBM9mw23BnYAJSgkgCh6iNBNZcAxD34iC4Hbtx/gFCalo+dJDGh+64pWa2N3z3tXRqJyINIa3cxTciAwUaw5ZeuXEgnZ5L4mtNkK+4t6NOh92VoJVcFe8uXThyyZ+V9Hv9e5yOhez5llyytbgASMc8+a/sE9UJ1ATCFUwMPPho8E6kahG/KJDjkaBqmMC6U6YZCJAvMlvECMFudL8VgT8hVdt2MrIzDho9CBBpE0QzyM5wS4SQDD8NgKJjQApc5MwxCcznWauNLmVIUnLif/XtI4agGT1mxWR3MKWjzZYK9JmuD91+vP3RsCGTMS2uXC4y1r6zoTE7b40LJejjPFFYax6Xy88mmmAAAAAElFTkSuQmCC"
        ></image>
      </defs>
    </svg>
  );
}
