import React from 'react';


export const TokenomicsIconHulk = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="223"
      height="251"
      fill="none"
      className={'tokenomicsIconHulk'}
      viewBox="0 0 223 251"
    >
      <path
        fill="url(#paint0_linear_1345_190)"
        d="M119.751 0v19.306c-2.726-.19-5.475-.291-8.25-.291-2.774 0-5.524.1-8.249.29V0H79.928v23.352C33.727 36.358 0 77.165 0 125.501c0 48.335 33.727 89.143 79.928 102.147V251h23.324v-19.306c2.725.19 5.475.291 8.249.291 2.775 0 5.524-.101 8.25-.291V251h23.323v-23.352C189.276 214.644 223 173.836 223 125.501c0-48.336-33.724-89.144-79.926-102.149V0h-23.323z"
      ></path>
      <path
        fill="url(#paint1_radial_1345_190)"
        d="M5.314 125.501c0 56.006 47.541 101.41 106.187 101.41 58.645 0 106.187-45.404 106.187-101.41 0-56.009-47.542-101.411-106.187-101.411C52.855 24.09 5.314 69.492 5.314 125.5z"
      ></path>
      <path
        fill="url(#paint2_linear_1345_190)"
        d="M136.796 143.346v54.176l37.944-33.985v-39.405l-37.944 19.214z"
      ></path>
      <path
        fill="url(#paint3_linear_1345_190)"
        d="M136.796 91.376l-50.763 25.708V53.478L48.09 87.463v76.074l37.943 33.985v-35.43l88.877-45.008v-29.62h-.171l-37.943-33.986v37.898z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1345_190"
          x1="111.501"
          x2="111.501"
          y1="307.522"
          y2="-91.736"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C7E0E"></stop>
          <stop offset="0.236" stopColor="#48B825"></stop>
          <stop offset="0.273" stopColor="#48B825"></stop>
          <stop offset="0.489" stopColor="#67F53D"></stop>
          <stop offset="0.501" stopColor="#67F53D"></stop>
          <stop offset="0.763" stopColor="#54D12C"></stop>
          <stop offset="0.77" stopColor="#54D12C"></stop>
          <stop offset="1" stopColor="#348E15"></stop>
        </linearGradient>
        <radialGradient
          id="paint1_radial_1345_190"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(73.19456 -82.38481 81.81143 72.68513 109.73 135.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17BB05"></stop>
          <stop offset="0.147" stopColor="#17BB05"></stop>
          <stop offset="1" stopColor="#005701"></stop>
        </radialGradient>
        <linearGradient
          id="paint2_linear_1345_190"
          x1="111.502"
          x2="111.502"
          y1="611.18"
          y2="-118.51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C7E0E"></stop>
          <stop offset="0.297" stopColor="#48B825"></stop>
          <stop offset="0.325" stopColor="#48B825"></stop>
          <stop offset="0.489" stopColor="#67F53D"></stop>
          <stop offset="0.501" stopColor="#67F53D"></stop>
          <stop offset="0.763" stopColor="#54D12C"></stop>
          <stop offset="0.77" stopColor="#54D12C"></stop>
          <stop offset="1" stopColor="#348E15"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_1345_190"
          x1="111.501"
          x2="111.501"
          y1="40.666"
          y2="254.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C7E0E"></stop>
          <stop offset="0.236" stopColor="#48B825"></stop>
          <stop offset="0.273" stopColor="#48B825"></stop>
          <stop offset="0.489" stopColor="#67F53D"></stop>
          <stop offset="0.501" stopColor="#67F53D"></stop>
          <stop offset="0.763" stopColor="#54D12C"></stop>
          <stop offset="0.77" stopColor="#54D12C"></stop>
          <stop offset="1" stopColor="#348E15"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
