import { Container } from "react-bootstrap";
import Title from "./Title";
import '../style/scss/advisorsBoard.scss';

const AdvisorsBoard = () => {
    const Advisors = [
        {avartar : require('../style/images/blockchain.png'), name: 'Blocklabchain', job: "Digital agency "},
        {avartar : require('../style/images/uber.png'), name: 'Uber', job: "VTC leader in the world"},
        {avartar : require('../style/images/osborne.png'), name: 'Osborne Clarke', job: "International law firm"},
    ]
    return (
        <Container>
            <Title title={"Advisors Board"} />
            <div className="advisors-board mt-5">
                {
                    Advisors.map((member, index) => {
                        return (
                          <div className="profile" key={member.name}>
                            <div className="profile-img">
                              <img src={member.avartar} alt={member.name}/>
                            </div>
                            <p className="name text-center">{member.name}</p>
                            <p className="job text-center">{member.job}</p>
                          </div>
                        )
                    })
                }
            </div>
        </Container>
    )
}

export default AdvisorsBoard;
