
import React, { Component, useEffect } from 'react';
import Highcharts from 'highcharts';
import addHighchartsMore from 'highcharts/highcharts-more';
import {
    HighchartsChart, Chart, HighchartsProvider, XAxis, YAxis, Title, Legend, ColumnSeries, SplineSeries, PieSeries
} from 'react-jsx-highcharts';
import { useMediaQuery } from 'react-responsive';
import LogoImg from "../style/images/logo.png"
// Apply Highcharts More module
addHighchartsMore(Highcharts);

const CustomChart = () => {
    const isTabletOrMobile3 = useMediaQuery({ query: '(max-width: 550px)' });
    useEffect(() => {
        (function (H) {
            H.seriesTypes.pie.prototype.animate = function (init) {
                const series = this,
                    chart = series.chart,
                    points = series.points,
                    {
                        animation
                    } = series.options,
                    {
                        startAngleRad
                    } = series;

                function fanAnimate(point, startAngleRad) {
                    const graphic = point.graphic,
                        args = point.shapeArgs;

                    if (graphic && args) {

                        graphic
                            // Set inital animation values
                            .attr({
                                start: startAngleRad,
                                end: startAngleRad,
                                opacity: 1
                            })
                            // Animate to the final position
                            .animate({
                                start: args.start,
                                end: args.end
                            }, {
                                duration: animation.duration / points.length
                            }, function () {
                                // On complete, start animating the next point
                                if (points[point.index + 1]) {
                                    fanAnimate(points[point.index + 1], args.end);
                                }
                                // On the last point, fade in the data labels, then
                                // apply the inner size
                                if (point.index === series.points.length - 1) {
                                    series.dataLabelsGroup.animate({
                                        opacity: 1
                                    },
                                        void 0,
                                        function () {
                                            points.forEach(point => {
                                                point.opacity = 1;
                                            });
                                            series.update({
                                                enableMouseTracking: true
                                            }, false);
                                            chart.update({
                                                plotOptions: {
                                                    pie: {
                                                        innerSize: '40%',
                                                        borderRadius: 8
                                                    }
                                                }
                                            });
                                        });
                                }
                            });
                    }
                }

                if (init) {
                    // Hide points on init
                    points.forEach(point => {
                        point.opacity = 0;
                    });
                } else {
                    fanAnimate(points[0], startAngleRad);
                }
            };
        }(Highcharts));

        Highcharts.chart('chartcontainer', {
            chart: {
                backgroundColor: "transparent",
                plotBackgroundColor: "transparent",
                plotShadow: true,
                type: 'pie',
                width: isTabletOrMobile3 ? 360 : 500,
                height: 500
            },
            fill: {
                color: "transparent"
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    borderWidth: 0,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b><br>{point.percentage}%',
                        distance: 10
                    }
                }
            },
            series: [{
                // Disable mouse tracking on load, enable after custom animation
                enableMouseTracking: true,
                animation: {
                    duration: 1000
                },
                colorByPoint: false,
                data: [{
                    name: 'Gaming',
                    y: 5,
                    color: '#BDFFA9'
                }, {
                    name: 'Advisors',
                    y: 5,
                    color: '#90FF6D'
                }, {
                    name: 'Marketing',
                    y: 10,
                    color: '#24FF00'
                },
                {
                    name: 'Presale',
                    y: 30,
                    color: '#AF0'
                }, {
                    name: 'Car Investment',
                    y: 12,
                    color: '#48B825'
                }, {
                    name: 'CEX',
                    y: 10,
                    color: '#7EC06A'
                }, {
                    name: 'Team',
                    y: 8,
                    color: '#E2F4D4'
                }, {
                    name: 'Raydium DEX',
                    y: 20,
                    color: '#CEEEB5'
                },]
            }]
        });

    }, [])

    return (
        <figure class="highcharts-figure" style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <div id="chartcontainer"  >
            </div>
            <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', marginTop: isTabletOrMobile3 ? "0px" : '10px', marginRight: isTabletOrMobile3 ? "16px" : '0px' }}>
                <img src={LogoImg} alt="logo" style={{ width: isTabletOrMobile3 ? '18px' : '22px', height: isTabletOrMobile3 ? "18px" : '22px' }} />
                <div style={{
                    color: "white",
                    fontSize: isTabletOrMobile3 ? "13px" : '18px',
                    textAlign: 'center',
                    fontWeight: '800'
                }}>
                    Hulk
                </div>
            </div>
        </figure>

    );
}

export default CustomChart;