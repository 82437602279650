import { Button, Card, Col, Container, Nav, Navbar, ProgressBar, Row } from "react-bootstrap";
import '../style/scss/banner.scss';
import { Bounce, Slide } from "react-awesome-reveal";
import { useWindowScrollPositions } from "../style/js/globalFuns";
import { useEffect, useRef, useState } from "react";
import { LogoIcon } from "./svg/LogoIcon";
import CustomCarosuel from "./CustomCarosuel";

import MenuImg from "../style/images/menu.svg"
import { useMediaQuery } from "react-responsive";


const Banner = () => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' });
  const [, setSectionActived] = useState('home');
  const [isMenu, setIsMenu] = useState(false);
  const [sectionActive, setSectionActive] = useState('HomeSection');
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const navbar = useRef();
  const sections = useRef([]);
  const navMenus = [
    // { name: 'home', redirect: 'HomeSection' },
    { name: 'project', redirect: 'ProjectSection' },
    { name: 'nft', redirect: 'NFTSection' },
    { name: 'token', redirect: 'TokenomicsSection' },
    { name: 'roadmap', redirect: 'RoadMapSection' },
    // {name: 'video', redirect: 'VideoSection'},
    { name: 'app', redirect: 'AppSection' },
    // { name: 'team', redirect: 'TeamSection' },
    // { name: 'partners', redirect: 'PartnersSection' },
    { name: 'contact', redirect: 'ContactSection' }
  ];
  const { scrollY } = useWindowScrollPositions();
  const handleScroll = () => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection = null;

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (pageYOffset >= sectionOffsetTop - 140 && pageYOffset < sectionOffsetTop + sectionHeight) {
        newActiveSection = section.id;
      }
    });

    let documentHeight = document.body.scrollHeight;
    let currentScroll = window.scrollY + window.innerHeight;
    // When the user is [modifier]px from the bottom, fire the event.
    let modifier = 200;
    if (currentScroll + modifier > documentHeight) {
      newActiveSection = 'ContactSection';
    }

    setSectionActive(newActiveSection);
  };
  useEffect(() => {
    sections.current = document.querySelectorAll('[data-section]');
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (scrollY > 150) {
      navbar.current.classList.add('scroll-fixed');
      // navbar.current.style.transform = `translateY(-44px)`;
      navbar.current.style.backgroundColor = `black`;
    } else {
      // navbar.current.style.transform = `translateY(0px)`;
      navbar.current.classList.remove('scroll-fixed');
      navbar.current.style.backgroundColor = `transparent`;
    }
  });

  const handleSection = (name) => {
    setSectionActived(name);
  }

  return (
    <Container fluid className="banner"  >

      <div className="d-flex">
        <Navbar expanded={isNavCollapsed} fixed="top" ref={navbar}>
          <Container>
            <Navbar.Brand href="#" >
              {/*<img src={Logo} alt="" width="65" height="32" />*/}
              <LogoIcon />

              <span>Hulk Cars</span>
            </Navbar.Brand>

            {
              !isTabletOrMobile &&
              <div className="hulk-normal">
                Hulk Cars standard in the tokenization of luxury car rentals
              </div>
            }
            {/* <div style={{ color: "#fff" }}>
              Hulk Car standard in the tokenization of luxury car rentals
            </div> */}
            {
              !isTabletOrMobile ?
                <>
                  <Navbar.Toggle onClick={handleNavCollapse} aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav style={{ gap: '18px' }}>
                      {
                        navMenus.map((item, index) => {
                          return (
                            <div key={index} className="hulk-nav nav-link">
                              <a href={`#${item.redirect}`} className={sectionActive === `${item.redirect}` ? 'actived' : ''} onClick={() => {
                                handleSection(item.name);
                                handleNavCollapse();
                              }}>{item.name}</a>
                            </div>
                          )
                        })
                      }
                    </Nav>
                    <Button className="presale-button">
                      PRESALE
                    </Button>
                  </Navbar.Collapse>
                </>
                :
                <>
                  <Button className="menu-button" onClick={() => {
                    setIsMenu(!isMenu)
                  }}>
                    <img src={MenuImg} alt="menuimg" />
                  </Button>
                </>
            }{
              isMenu &&
              <div className="mobile-menu">
                {
                  navMenus.map((item, index) => {
                    return (
                      <div key={index} className="hulk-nav nav-link">
                        <a href={`#${item.redirect}`} className={sectionActive === `${item.redirect}` ? 'actived' : ''} onClick={() => {
                          handleSection(item.name);
                          setIsMenu(!isMenu);
                        }}>{item.name}</a>
                      </div>
                    )
                  })
                }
              </div>}
          </Container>
        </Navbar>
      </div>
      <Row>
        <CustomCarosuel />
      </Row>
    </Container>
  )
}

export default Banner;
