import { Col, Container, Row } from "react-bootstrap"
import Title from "./Title"
import '../style/scss/tokenomics.scss';
import { CopyIcon } from "./svg/CopyIcon";
import { HulkIcon } from "./svg/HulkIcon";
import AuditIconSrc from '../style/images/audit.png';
import { TokenomicsGraph } from "./TokenomicsGraph";
import { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Flip, Slide, Bounce } from "react-awesome-reveal";
import { animation, useWindowScrollPositions } from "../style/js/globalFuns";
import { PieChart, Pie, Tooltip } from "recharts";
import CustomChart from "./CustomChart";

// import '../style/scss/tokenomics.scss';
// ChartJS.register(ArcElement, Tooltip, Legend);


const Tokenomics = () => {
  const animated = useRef();
  const { scrollY } = useWindowScrollPositions();
  const TOKEN = 'F1C6XiAmwyxviv3zacQwTnoeRMmV1GrDzATWMVeN6MU4';
  const [isCopied, setIseCopied] = useState(false);
  useEffect(() => {
    animation(animated);
  }, [scrollY]);

  const data = [
    { name: "Marketing & Dev", rate: 10, fill: "#24FF00" },
    { name: "Advisors", rate: 5, fill: "#90FF6D" },
    { name: "Gaming", rate: 5, fill: "#BDFFA9" },
    { name: "Raydium", rate: 20, fill: "#CEEEB5" },
    { name: "Team", rate: 8, fill: "#E2F4D4" },
    { name: "CEX", rate: 10, fill: "#7EC06A" },
    { name: "Car Investment", rate: 12, fill: "#48B825" },
    { name: "Presale", rate: 30, fill: "#AF0" },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (data, index) => {
    setActiveIndex(index);
  }


  return (
    <>
      <Container id="TokenomicsSection" data-section={'TokenomicsSection'}>
        <Title title={"Tokenomics"} />
        <Row ref={animated} className={'tokenomics-wrapper align-items-center'}>
          <Col lg={6}>
            <Slide>

              <h3>Hulk Token</h3>
              <div className={'tokenomics-desc'}>
                <p>HULK token is a utilities token tied to the entire Hulk Ecosystem. It will give many advantages to our partners, access to premium services and exclusive discounts see the whitepaper to find out more.</p>
              </div>
              <div className={'tokenomics-contact'}>
                <span>Contract address:&nbsp;</span>
                <span className={'tokenomics-code-wrapper'}>
                  {isCopied && (
                    <Bounce className={'copied-status-wrapper'}>
                      <div className={'copied-status'}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-check2-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 8a5.5 5.5 0 018.25-4.764.5.5 0 00.5-.866A6.5 6.5 0 1014.5 8a.5.5 0 00-1 0 5.5 5.5 0 11-11 0" />
                          <path d="M15.354 3.354a.5.5 0 00-.708-.708L8 9.293 5.354 6.646a.5.5 0 10-.708.708l3 3a.5.5 0 00.708 0z" />
                        </svg>
                        Contract address copied!</div>
                    </Bounce>
                  )}

                  <span className={'tokenomics-code'}>
                    {TOKEN}
                  </span>

                  <CopyToClipboard
                    text={TOKEN}
                    onCopy={() => {
                      setIseCopied(true);
                      setTimeout(() => {
                        setIseCopied(false);
                      }, 3000)
                    }}>
                    <button type={'button'}><CopyIcon /></button>
                  </CopyToClipboard>
                </span>
              </div>
              <div className={'tokenomics-info'}>
                <TokenomicsBlock title={'Token name'} value={'HULK'} />
                <TokenomicsBlock title={'Symbol'} value={'HULK'} />
                <TokenomicsBlock title={'Total Supply'} value={'100.000.000'} />
                <TokenomicsBlock title={'Available on'} value={'Solana'} />
              </div>
              <div className={'tokenomics-btns'}>
                <button type={'button'}><HulkIcon /> Buy Hulk</button>
                <button type={'button'}><img src={AuditIconSrc} alt={'Audit'} /> Audit</button>
              </div>
            </Slide>
          </Col>
          <Col lg={6} >
            <CustomChart >
            </CustomChart>

            {/* <PieChart width={700} height={700}>
              <Tooltip />
              <Pie
                onMouseEnter={onPieEnter}
                activeIndex={activeIndex}
                data={data}
                dataKey="rate"
                labelLine={true}
                outerRadius={200}
                innerRadius={100}
                // onMouseEnter={onPieEnter}
                label={({ name, rate }) =>
                  `${name}: ${rate}`
                }
              />
            </PieChart> */}
            <div className={'tokenomics-circe-wrapper'}>
              <Col lg={6} md={6} sm={6} xs={12} className="chart d-flex justify-content-center">
              </Col>

              {/* <Flip >
                  <TokenomicsCircle/>
                </Flip> */}
            </div>
          </Col>
        </Row>
      </Container>
      <TokenomicsGraph />

    </>
  )
}

function TokenomicsBlock({ title = '', value = '' }) {
  return (
    <div className="tokenomics-info-block">
      <p>{title}:</p>
      <h4>{value}</h4>
    </div>
  )
}

function TokenomicsCircle() {
  return (
    <div className={'tokenomics-circe'}>
      <h5>0%</h5>
      <p>TRANSACTION FEES</p>
    </div>
  )
}


export default Tokenomics;
