import { Carousel, Col, Container, Row } from "react-bootstrap";
import Title from "./Title";
import '../style/scss/strategicPartner.scss';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { useRef } from "react";
import NextImg from "../style/images/next1.svg"
import PrevImg from "../style/images/prev1.svg"
import { useMediaQuery } from "react-responsive";

const StrategicPartner = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' });
    const swiperRef = useRef();
    // const partners = [
    //     {logo: require('../style/images/strategic/h1-client-img-1.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-2.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-3.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-4.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-5.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-6.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-7.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-8.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-9.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-10.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-11.png')},
    //     {logo: require('../style/images/strategic/h1-client-img-12.png')},
    // ];
    // const SubPartners = [
    //     { logo: require('../style/images/strategic/h2-client-img-1.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-2.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-3.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-4.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-5.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-6.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-7.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-8.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-9.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-10.png'), name: 'pancakeswap' },
    //     { logo: require('../style/images/strategic/h2-client-img-11.png'), name: "" },
    //     { logo: require('../style/images/strategic/h2-client-img-12.png'), name: "" },
    // ]

    const partners = [
        { logo: require('../style/images/strategic/uber.png'), name: "" },
        { logo: require('../style/images/strategic/blockchain.png'), name: "" },
        { logo: require('../style/images/strategic/bmw.png'), name: "" },
        { logo: require('../style/images/strategic/audi.png'), name: "" },
        { logo: require('../style/images/strategic/toyota.png'), name: "" },

    ];
    const SubPartners = [
        { logo: require('../style/images/strategic/h2-client-img-1.png'), name: "" },
        // { logo: require('../style/images/strategic/h2-client-img-2.png'), name: "" },
        // { logo: require('../style/images/strategic/h2-client-img-3.png'), name: "" },
        // { logo: require('../style/images/strategic/h2-client-img-4.png'), name: "" },
        // { logo: require('../style/images/strategic/h2-client-img-5.png'), name: "" },
        // { logo: require('../style/images/strategic/h2-client-img-6.png'), name: "" },
        { logo: require('../style/images/strategic/h2-client-img-7.png'), name: "" },
        // { logo: require('../style/images/strategic/h2-client-img-8.png'), name: "" },
        // { logo: require('../style/images/strategic/h2-client-img-9.png'), name: "" },
        // { logo: require('../style/images/strategic/h2-client-img-10.png'), name: 'pancakeswap' },
        { logo: require('../style/images/strategic/h2-client-img-13.png'), name: "" },
        { logo: require('../style/images/strategic/h2-client-img-11.png'), name: "", url: "https://www.dx.app/" },
        { logo: require('../style/images/strategic/h2-client-img-14.png'), name: "" },
        { logo: require('../style/images/strategic/h2-client-img-15.png'), name: "" },
    ]
    return (
        <div className="strategic-partners" id="PartnersSection">
            <Title title={"Strategic Partners"} />
            <Container>
                <Row className="row-style">
                    <button className='button-next' onClick={() => swiperRef.current.swiper.slideNext()}>
                        <img src={PrevImg} alt="next" className="button-next-img" />
                    </button>

                    <Swiper
                        slidesPerView={1}
                        loop={true}
                        // width={"100%"}
                        navigation={true}
                        // spaceBetween={20}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        modules={[Navigation, Autoplay]}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => swiper.slideTo(1, 0.1, false)}
                        ref={swiperRef}
                        style={{ flexShrink: 1 }}
                        pagination={{ clickable: true }}
                    >
                        <SwiperSlide className="image-item-swiper" >
                            <div className="partners-group">
                                {
                                    SubPartners.map((item, index) => {
                                        return <img src={item.logo} alt="logo" />
                                    })
                                }
                            </div>

                        </SwiperSlide>
                        <SwiperSlide className="image-item-swiper" >
                            <div className="partners-group">
                                {
                                    partners.map((item, index) => {
                                        return <img src={item.logo} alt="logo" />
                                    })
                                }
                            </div>

                        </SwiperSlide>

                    </Swiper>


                    <button className='button-next' onClick={() => swiperRef.current.swiper.slideNext()}>
                        <img src={NextImg} alt="next" className="button-next-img" />
                    </button>

                    {/* <Col>
                        <Carousel className="" interval={4000}>
                            <Carousel.Item>
                                <Row>
                                    {
                                        SubPartners.map((subpartner, index) => {
                                            if (index < 6) {
                                                return (
                                                    <Col lg={2} md={4} sm={12} xs={12} key={index} className="d-flex justify-content-center sub-item">
                                                        <img src={subpartner.logo} alt="" width="" height="" />
                                                    </Col>
                                                )
                                            }
                                        })
                                    }
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    {
                                        SubPartners.map((subpartner, index) => {
                                            if (index > 5) {
                                                return (
                                                    <Col lg={2} md={4} sm={12} xs={12} key={index} className="d-flex justify-content-center sub-item">
                                                        <img src={subpartner.logo} alt="" width="200" height={subpartner.name === "pancakeswap" ? '175' : '160'} />
                                                    </Col>
                                                )
                                            }
                                        })
                                    }
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Col> */}
                </Row>
            </Container>
        </div >
    )
}

export default StrategicPartner;