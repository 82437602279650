import React from 'react';
import '../style/scss/TokenomicsGraph.scss';
import {Container} from "react-bootstrap";
import {TokenomicsIconHulk} from "./svg/TokenomicsIconHulk";
import {useEffect, useRef} from "react";
import {animation, useWindowScrollPositions} from "../style/js/globalFuns";
import { Zoom} from "react-awesome-reveal";


export const TokenomicsGraph = () => {
  const animated = useRef();
  const { scrollY } = useWindowScrollPositions();

  useEffect(() => {
    animation(animated);
  }, [scrollY]);

  return (<section className={'tokenomics-graph'}>
    <Container ref={animated}>
      <Zoom>

      <div className="tokenomics-graph-wrapper" >
        <div className={'tokenomics-graph-block-wrapper tokenomics-graph-block-wrapper--top'}>
          <Block
            title={'Mint NFT Marketplace Cars'}
            desc1={'10% in Buyback automatically for each sale of mint from NFT'}
            desc2={'Offer premium access  whitelist or at a discount with Hulk Token'}
            pos={1}/>
          <Block
            title={'Hulk Cars <br/> Partners'}
            desc1={'Get a discount with Hulk Token'}
            pos={2}/>
        </div>
        <div className={'tokenomics-graph-block-wrapper tokenomics-graph-block-wrapper--center'}>
          <Block
            desc1={'5% to 10% profit in buyback in the Hulk Token'}
            title={'Customers who uses our Hulk Rent APP'} pos={3}/>
          <TokenomicsIconHulk />
          <Block
            title={'Advertising service on our Cars'}
            desc1={'10% in buyback for the Hulk Token'}
            pos={4}/>
        </div>
        <div className={'tokenomics-graph-block-wrapper tokenomics-graph-block-wrapper--bottom'}>
          <Block title={'The vehicle fleet from Hulk Cars'} pos={5}/>
        </div>

      </div>
      </Zoom>
    </Container>
  </section>);
}

function Block({pos= 1, title = '', desc1='', desc2= ''}) {
  return (
    <div className={`tokenomics-graph-block tokenomics-graph-block--${pos}`}>
      {desc1 && (<div className={'tokenomics-graph-block-desc tokenomics-graph-block-desc--1'}>{desc1}</div>)}
      {desc2 && (<div className={'tokenomics-graph-block-desc tokenomics-graph-block-desc--2'}>{desc2}</div>)}
      <span dangerouslySetInnerHTML={{__html: title}} />
    </div>
  )
}
