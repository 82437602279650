import { Col, Container, Row } from "react-bootstrap"
import Title from "./Title"
import '../style/scss/ourTeam.scss';
import { LinkedinIcon } from "./svg/LinkedinIcon";
import { TwitterIcon } from "./svg/TwitterIcon";

const OurTeam = () => {
    const TeamMembers = [
        { avartar: require('../style/images/team/aladin.png'), name: 'Aladdin', job: 'CEO & Founder', desc: 'Head of Development', linkedin: 'https://www.linkedin.com/in/alaeddine-abu-saed-b466b3139/', twitter: '' },
        { avartar: require('../style/images/team/adam.png'), name: 'Adam', job: 'COO & Co Founder', desc: 'Head of Operations', linkedin: 'https://www.linkedin.com/in/adam-abu-saed-b8a051301/', twitter: '' },
        // { avartar: require('../style/images/team/team-img-3.png'), name: 'Nicolas', job: 'CFO', desc: 'Accountant', linkedin: '', twitter: '' },
        { avartar: require('../style/images/team/naim.png'), name: 'Nahim', job: 'CTO', desc: 'Technical Manager', linkedin: 'https://www.linkedin.com/in/nahim-slimani/', twitter: '' },
        // {avartar: require('../style/images/team/team-img-5.png'), name: 'Patrick Richardson', job: 'CEO & Founder', desc: 'Aliquam lorem ante, dapibus in, vive quis, feugiat a, tellus. Phase llus viver nulla ut metus varius'},
        // {avartar: require('../style/images/team/team-img-6.png'), name: 'Jeffery Payne', job: 'CEO & Founder', desc: 'Aliquam lorem ante, dapibus in, vive quis, feugiat a, tellus. Phase llus viver nulla ut metus varius'},
        // {avartar: require('../style/images/team/team-img-7.png'), name: 'Jean Parker', job: 'CEO & Founder', desc: 'Aliquam lorem ante, dapibus in, vive quis, feugiat a, tellus. Phase llus viver nulla ut metus varius'},
        // {avartar: require('../style/images/team/team-img-8.png'), name: 'Samantha Andrews', job: 'CEO & Founder', desc: 'Aliquam lorem ante, dapibus in, vive quis, feugiat a, tellus. Phase llus viver nulla ut metus varius'},
    ]
    return (
        <Container id="TeamSection" data-section={'TeamSection'}>
            <Title title={"Our Team"} />
            <Row className="our-team">
                {
                    TeamMembers.map((member, index) => {
                        return (
                            <Col lg={4} md={4} sm={3} xs={12} key={index} className="content">
                                <div className="profile">
                                    <img src={member.avartar} alt="" width="265" height="265" />
                                    <p className="name text-center">{member.name}</p>
                                    <p className="job text-center">{member.job}</p>
                                    <p className="desc text-center">{member.desc}</p>
                                    <div className={'our-team-social-links'}>
                                        {member.linkedin && (<a href={member.linkedin} rel="noreferrer" target={'_blank'}><LinkedinIcon /></a>)}
                                        {member.twitter && (<a href={member.twitter} rel="noreferrer" target={'_blank'}><TwitterIcon /></a>)}
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}

export default OurTeam;
