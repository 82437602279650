import { Col, Container, Row } from "react-bootstrap"
import Title from "./Title"
import InsuredCar1Img from '../style/images/insured-car 1.png';
import Quick1Img from '../style/images/quick 1.png';
import SvgImg from '../style/images/svg.png';
import Nft3Img from '../style/images/nft (3) 1.png';
import CarSkeletonImg from '../style/images/home-img-9.png';
import '../style/scss/project.scss';
import { useWindowScrollPositions, animation } from "../style/js/globalFuns";
import { useEffect, useRef } from "react";
import { Flip, Slide } from "react-awesome-reveal";
import HulkLogo from '../style/images/hulkToken.svg';
import HulkLogo2 from '../style/images/logo2.svg';

const Project = () => {
    const projectItem = useRef();
    const Projects = [
        {avartar: HulkLogo, title: 'Hulk Token', desc: 'Hulk token allows you to be an investor in the Hulk cars via tokenization because part of the profits will be injected into the token via the buyback'},
        {avartar: require('../style/images/car 1.png'), title: 'Car Rental', desc: 'Tokenized car rental receive rent shares every month with a rating system for the best renters and drivers with on our car sharing platform'},
        {avartar: require('../style/images/nft 1.png'), title: 'NFT Dividend', desc: 'NFTs will be associated with each vehicle you receive dividends each month on rental. Your NFT is proof of ownership you can sell it at any time'},
    ];
    const { scrollY } = useWindowScrollPositions();

    useEffect(() => {
        animation(projectItem);
    }, [scrollY]);

    return (
        <Container id="ProjectSection" data-section={'ProjectSection'}>
            <Title title={"Project"} />
            <Row className="project reveal" ref={projectItem}>
                {
                    Projects.map((project, index) => {
                        return (
                            <Col lg={4} md={4} sm={12} xs={12} className="d-flex flex-column project-col" key={index}>
                                <div className={'project-wrapper'}>
                                  <div className={'project-img'}>
                                    <img src={project.avartar} alt="" width="130" height="110" />
                                  </div>
                                  <h2 className="text-center">{project.title}</h2>
                                  <p className="text-center">{project.desc}</p>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row className="project-sub">
                <Col lg={5} md={5} xs={12} className="d-flex justify-content-between align-items-center">
                    <div className="">
                        <img src={InsuredCar1Img} alt="" width="34" height="34" />
                        <p>Car 100%<br />insured</p>
                    </div>
                    <div className="right">
                        <img src={Quick1Img} alt="" width="34" height="34" />
                        <p>Rapid<br />development</p>
                    </div>
                </Col>
                <Col lg={2} md={2} xs={0} />
                <Col lg={5} md={5} xs={12} className="d-flex justify-content-between align-items-center">
                    <div className="">
                        <img src={SvgImg} alt="" width="34" height="34" />
                        <p>Evolution of the<br />car range</p>
                    </div>
                    <div className="right">
                        <img src={Nft3Img} alt="" width="34" height="34" />
                        <p>Easy car owner<br />fast payment</p>
                    </div>
                </Col>
            </Row>
            <Row className="car-rental">
                <Col lg={9} md={8} sm={12} xs={12} className="content">
                    <div className="w-100 car-rental-block">
                        <h6>Project of Hulk Cars</h6>
                        <h2 className="mt-4">Car Rental</h2>
                        <p style={{}} className="mt-4">
                          We buy and rent our cars with Uber drivers and we have our own car sharing platform. Subsequently, we will have our own private address book to develop and will have partnerships with hotels, restaurants, travel agencies, etc.
                          <br/>
                          <br/>
                          Each month a portion of the profits will be redeemed in our token. If you own an NFT, you receive a portion of the profits on the car associated with your NFT. The profits generated will make it possible to buy new cars and finance development. If you hold a Hulk token, you benefit from discounts from all our partners by presenting your assets. We will initially be in Western Europe and the United Arab Emirates.
                        </p>
                    </div>
                </Col>

                <Col lg={3} md={4} sm={12} xs={12} className="d-flex justifyf-content-center align-items-center flex-column logo">
                    <div className={'title-hulk-cars'}>
                      <h6>Project of Hulk Cars</h6>
                      <h2 className="mt-4">Car Rental</h2>
                    </div>
                      <Flip>
                        <img src={HulkLogo2} alt="" width="100%" height="100%" />
                      </Flip>


                </Col>
            </Row>
            <Row className="tokenized-car">

                <Col lg={7} md={7} sm={7} xs={12} className="d-flex justify-content-center align-items-center logo">
                    <Slide>
                        <img src={CarSkeletonImg} alt="" width="100%" height="100%" />
                    </Slide>
                </Col>
                <Col lg={5} md={5} sm={5} xs={12} className="content">
                    <h6 className="mt-4">NFT Hulk Cars</h6>
                    <h2 className="mt-4">Tokenized Car</h2>
                    <p className="mt-4">
                      Tokenized car rental receive rent shares every month with a rating system for the best renters and drivers on your NFT.<br /><br />
                      Tokenized car rental receives rent shares each month with a rating system for the best renters and drivers. When the car is sold you receive a % of the sale.<br /><br />
                      If you hold Hulk Token or an NFT you benefit from discounts from all our partners by presenting your holdings.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Project;
