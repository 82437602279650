import React from 'react';


export const CopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path fill="url(#pattern0)" d="M0 0H12.8V12.8H0z" />
      <defs>
        <pattern
          id="pattern0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.0625)" xlinkHref="#image0_1638_65" />
        </pattern>
        <image
          id="image0_1638_65"
          width="16"
          height="16"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACUSURBVHgB7ZMxCoQwEEVndrPgLba11tpDeAkv4fGstTVHUaJ8JwQhRkENlj7BkMA8UrzwYCbQLbhLft983Sm3QBNzczoLKsMjJ5DhRKnqbH4wcx6efSiS0cy1CNtoARh/+WfRgpVX8IDAhSSFHUWyB6lUpwMBd3QZ1gD6jcB/GDEof2PzdIVdACjkRvJ52LZtnnSDBbvVMNOXGbz3AAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
}
