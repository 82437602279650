import React from 'react';
import '../style/scss/404.scss';
import {Link} from "react-router-dom";
import {Container} from "react-bootstrap";

export const Page404 = () => {
  return (<div className={'page404'}>
    <div className={'page404-sec-1'}>
      <Container>
        <h1>Error Page</h1>

      </Container>
    </div>
    <div className={'page404-sec-2'}>
      <Container className={'text-center'}>
        <h2>404</h2>
        <h4>Page you are looking is not found</h4>
        <p>The page you are looking for does not exist. It may have been moved, or removed altogether. Perhaps you can return back to the sites homepage and see if you can find what you are looking for.</p>
        <Link className={"page404-btnBackToHome"} to={'/'}>Back to home</Link>
      </Container>
    </div>
  </div>);
}
