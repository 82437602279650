import React from 'react';


export const TwitterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="28"
      fill="none"
      viewBox="0 0 30 28"
    >
      <path
        fill="url(#twitter)"
        d="M0 0H29.06V26.51H0z"
        transform="translate(0 .541)"
      ></path>
      <defs>
        <pattern
          id="twitter"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use
            transform="scale(.02941 .03226)"
            xlinkHref="#image0_1397_7"
          ></use>
        </pattern>
        <image
          id="image0_1397_7"
          width="34"
          height="31"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAfCAYAAACCox+xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK2SURBVHgB7Ze9UttAEMd3T1IIqajzAdIkffwIfgT6wGDXGQdcESaF5YIhpDHEk9phAjXhCdAbQHpmDsxXSneZYHSbXQ8wjmOfZCe2U/BvbN3tnX7a2927Q+iQ1nqKf6ZguGoEQdBob8DbPyen3xcN0RIACUQDhqvWO2Iw5RfTTz/fter6ZU3XL/a1Ps3CiKT1WUafXBzo+nmt1XCkz3IMoWFMEpij+llOOa5ahDjOw7hk4qIDaoGX5YJgzBIGBf+J/jnIWqXmh5Va3+mP4pZg+jH2MpBJw2I+MZ0/VHcWecFDAmxB8IRRfN3M/+Q0nQA3Yx6oxrvXrw67jU21NJOed7D6aSdjs1mv7pQIaOMWQsSBl1Wupx+5rlYu5ppXV8e2Oawg4mae0XcNHax/3F7qaYiU69UlcOydmUnH62mTCPKwvcIiVPjLd1crv3tnrfIlK7C2eQhhb/nN3IbNJjFG3le397kz29F8aAztgYEIHPIVqhpYtFyYQ1u/MLg2gwoH6g+gTYfQZ4/4bV0ZpTDD/iy1bVfdhXAMKeQmGTiAu0nvsnJQOhBrjBQ5bTn6I/gLGYPf0tglpi9xLYCUX9V1vLnaSGOXCLJSzB+bmPI4gGdkjIxPY5uqxCsXpujPzEmUVNa0tqlAlgvzXw1QGfoSldN6IzWIaKUwH5rrZkCGNpNsuZpuMnwIfSgxfUWy8cnG5Xi4QAQ5uzWV3xbmQuhTVhAp346Lpdv4IMsRStKcZDkK8xEMoMQS3wKq8n4CMIuAL1Gq7N1oLvVgpE5EgwKIWqfE+6NihwSkcXO7G4tu3t0QED6+uRkYk2LwZoVB8Z2mDI5TYzIfRiytL33HUSVhaGWLPjkPAXFB7qJGwaHXbA777gtGTcwqBLlvbz2feRJiOx0pE0qKclqOIGYogthsBcGzSJ5+AZPrMq2dqsH2AAAAAElFTkSuQmCC"
        ></image>
      </defs>
    </svg>
  );
}
