import React from 'react';


export const HulkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="25"
      fill="none"
      viewBox="0 0 21 25"
      className={'HulkIcon'}
    >
      <path
        fill="url(#paint0_radial_1426_2922)"
        d="M1 12.554c0 5.354 4.477 9.695 10 9.695 5.522 0 10-4.34 10-9.695C21 7.2 16.521 2.86 11 2.86c-5.523 0-10 4.34-10 9.694z"
      ></path>
      <path
        fill="url(#paint1_linear_1426_2922)"
        d="M13.382 14.26v5.18l3.573-3.25v-3.767l-3.573 1.837z"
      ></path>
      <path
        fill="url(#paint2_linear_1426_2922)"
        d="M13.382 9.292l-4.78 2.458V5.67L5.027 8.917v7.273l3.573 3.248v-3.387l8.37-4.302V8.918h-.016L13.382 5.67v3.623z"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_1426_2922"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-48.807 20.29 -5.19) scale(10.4659 10.3736)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17BB05"></stop>
          <stop offset="0.147" stopColor="#17BB05"></stop>
          <stop offset="1" stopColor="#005701"></stop>
        </radialGradient>
        <linearGradient
          id="paint1_linear_1426_2922"
          x1="11"
          x2="11"
          y1="58.983"
          y2="-10.772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C7E0E" />
          <stop offset="0.297" stopColor="#48B825"></stop>
          <stop offset="0.325" stopColor="#48B825"></stop>
          <stop offset="0.489" stopColor="#67F53D"></stop>
          <stop offset="0.501" stopColor="#67F53D"></stop>
          <stop offset="0.763" stopColor="#54D12C"></stop>
          <stop offset="0.77" stopColor="#54D12C"></stop>
          <stop offset="1" stopColor="#348E15"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_1426_2922"
          x1="10.999"
          x2="10.999"
          y1="4.445"
          y2="24.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C7E0E" />
          <stop offset="0.236" stopColor="#48B825" />
          <stop offset="0.273" stopColor="#48B825" />
          <stop offset="0.489" stopColor="#67F53D" />
          <stop offset="0.501" stopColor="#67F53D" />
          <stop offset="0.763" stopColor="#54D12C" />
          <stop offset="0.77" stopColor="#54D12C" />
          <stop offset="1" stopColor="#348E15"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
