import { Button, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import "../style/scss/customCarosuel.scss"
import 'swiper/css';
import NextImg from "../style/images/next.svg"
import PrevImg from "../style/images/prev.svg"
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import Car01Img from "../style/images/cars/car01.jpg"
import Car02Img from "../style/images/cars/car02.png";
import Car03Img from "../style/images/cars/car03.png";
import Car04Img from "../style/images/cars/car04.png";
import Car05Img from "../style/images/cars/car05.png";
import Car06Img from "../style/images/cars/car06.png";
import Car07Img from "../style/images/cars/car07.png";
import Car08Img from "../style/images/cars/car08.png";
import Car09Img from "../style/images/cars/car09.png";
import Car10Img from "../style/images/cars/car10.png";
import Car11Img from "../style/images/cars/car11.png";
import Car12Img from "../style/images/cars/car12.png";
import Car13Img from "../style/images/cars/car13.png";

import { Autoplay, Scrollbar, Thumbs, FreeMode, Navigation } from 'swiper/modules';
import { useState } from "react";
import { useRef } from "react";
import { Next } from "react-bootstrap/esm/PageItem";
import { useMediaQuery } from "react-responsive";
const CustomCarosuel = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' });
    const isTabletOrMobile1 = useMediaQuery({ query: '(max-width: 951px)' });
    const isTabletOrMobile2 = useMediaQuery({ query: '(max-width: 700px)' });
    const isTabletOrMobile3 = useMediaQuery({ query: '(max-width: 550px)' });

    const images = [Car01Img, Car02Img, Car03Img, Car04Img, Car05Img, Car06Img, Car07Img, Car08Img, Car09Img, Car10Img, Car11Img, Car12Img, Car13Img];
    const swiperRef = useRef();
    const swiperRef1 = useRef();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
        <Container className="custom-carosuel" >
            <Swiper
                slidesPerView={1}
                loop={true}
                thumbs={{ swiper: swiperRef1?.current?.swiper }}
                navigation={true}
                modules={[Thumbs, Navigation, FreeMode]}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log("onswiper")}
                ref={swiperRef}
            >
                {
                    images.map((item, index) => {
                        return <SwiperSlide className="image-item-swiper">
                            <img src={item} className="image-item" />
                        </SwiperSlide>
                    })
                }
                <div className="bottom-text">
                    <div style={{
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: "start"
                    }}>
                        <div className="staring-at">
                            Starting at:
                        </div>
                        <div className="price">
                            $250
                        </div>
                    </div>
                    <div className="get-nft-rental">
                        Get your Lamborghini NFT through rental
                    </div>
                </div>
                <div className="button-bar" >
                    <button className='button-next' onClick={() => {
                        swiperRef.current.swiper.slidePrev()
                    }}>
                        <div className="button-next-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.769 73.769">
                                <g id="Group_161" data-name="Group 161" transform="translate(-79.672 -359.489)">
                                    <path id="Path_115" data-name="Path 115" d="M2268.14,399.151l24.54,14.168V384.983Z" transform="translate(-2167.944 -2.777)" fill="currentColor"></path>
                                    <circle id="Ellipse_16" data-name="Ellipse 16" cx="36.385" cy="36.385" r="36.385" transform="translate(80.172 359.989)" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="1"></circle>
                                </g>
                            </svg>
                        </div>
                        {/* <img src={PrevImg} alt="prev" className='button-next-img' /> */}
                    </button>
                    <div className="hulk-main">
                        <div className="hulk-title">
                            Hulk Cars
                        </div>
                        <div className="hulk-info">
                            The world's first RWA project to tokenize cars with NFTs
                        </div>

                        {
                            !isTabletOrMobile &&
                            <Row className="row-style">
                                <button className="button-border">
                                    HULK TOKEN
                                </button>
                                <a className="button-border" href="/whitepaper.pdf" target="_blank">
                                    WHITE PAPER
                                </a>
                            </Row>
                        }
                    </div>
                    <button className='button-next' onClick={() => swiperRef.current.swiper.slideNext()}>
                        <div className="button-next-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.769 73.769">
                                <g id="Group_161" data-name="Group 161" transform="translate(-1779.512 -359.489)">
                                    <path id="Path_114" data-name="Path 114" d="M4000.7,399.151l-24.54-14.168V413.32Z" transform="translate(-2167.944 -2.777)" fill="currentColor"></path>
                                    <circle id="Ellipse_15" data-name="Ellipse 15" cx="36.385" cy="36.385" r="36.385" transform="translate(1780.012 359.989)" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="1"></circle>
                                </g>
                            </svg>
                        </div>
                        {/* <img src={NextImg} alt="next" className='button-next-img' /> */}
                    </button>
                </div>

            </Swiper>
            <Swiper
                ref={swiperRef1}
                onSwiper={(swiper) => { setThumbsSwiper(swiper) }}
                // onSwiper={setThumbsSwiper}
                scrollbar={{
                    hide: false,
                    draggable: true
                }}
                // loop={true}
                // slidesPerView={"auto"}
                watchSlidesProgress={true}
                slidesPerView={!isTabletOrMobile ? 5 : !isTabletOrMobile1 ? 4 : !isTabletOrMobile2 ? 3 : !isTabletOrMobile3 ? 2 : 1.5}
                spaceBetween={30}
                freeMode={true}
                navigation={true}
                modules={[FreeMode, Scrollbar, Thumbs]}
                onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            >
                <div className="blur-backgound">
                    {
                        images.map((item, index) => {
                            return <SwiperSlide >
                                <div className="sub-item" onClick={() => {
                                    // swiperRef.current.swiper.slideTo(index);
                                }}>
                                    <img src={item} className="image-sub-item" />
                                </div>
                            </SwiperSlide>
                        })
                    }
                </div>
            </Swiper>

        </Container >
    )
}

export default CustomCarosuel;
