import { Col, Container, Form, Row } from "react-bootstrap";
import LogoImg from '../style/images/logo.png';
import { BsTwitter, BsLinkedin, BsTelegram, BsDiscord, BsYoutube, BsFacebook, BsInstagram, BsGithub, BsLink } from 'react-icons/bs';
import { SiLinktree } from "react-icons/si";

import '../style/scss/footer.scss';

const Footer = () => {

    const FooterLinks = [
        // {name: 'ICO'},
        { name: 'Project', redirect: 'ProjectSection' },
        { name: 'NFT', redirect: 'NFTSection' },
        { name: 'Token', redirect: 'TokenomicsSection' },
        { name: 'Roadmap', redirect: 'RoadMapSection' },
        { name: 'App', redirect: 'AppSection' },
        // {name: 'Video'},
        { name: 'Team', redirect: 'TeamSection' },
        { name: 'Partners', redirect: 'PartnersSection' },
    ];
    const FooterDocuments = [
        { name: "Whitepaper", redirect: 'https://hulkcars.com/whitepaper.pdf' },
        { name: "Privacy Policy", redirect: 'https://hulkcars.com/PrivacyPolicy.pdf' },
        { name: "Legal Notice", redirect: 'https://hulkcars.com/LegalNotice.pdf' },
    ]
    return (
        <div className="footer" id="ContactSection" data-section={'ContactSection'}>
            <Container>
                <Row className="pt-5 pb-5">
                    <Col lg={3} md={3} sm={12} xs={12} className="footer-logo">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
                            <img src={LogoImg} alt="" width={32} height={32} />
                            <p style={{ color: "white", fontSize: 20, fontFamily: 'Urbanist', fontWeight: '800' }}>
                                Hulk Cars
                            </p>
                        </div>
                        <p>Hulk Cars is a UK-based tokenized car rental and car sharing platform.</p>
                        <div className="socials">
                            <BsFacebook />
                            <a href="https://twitter.com/HulkCars" target={'_blank'} rel={'noreferrer'}><BsTwitter /></a>
                            <a href="https://www.youtube.com/@HulkCars" target={'_blank'} rel={'noreferrer'}>
                                <BsYoutube />
                            </a>
                            <a href="https://linktr.ee/hulkcars" target={'_blank'} rel={'noreferrer'}>
                                <BsLinkedin />
                            </a>
                            <a href="https://t.me/hulkcars/" target={'_blank'} rel={'noreferrer'}>
                                <BsTelegram />
                            </a>
                            <a href="https://discord.com/invite/XXzJ7zqt67" target={'_blank'} rel={'noreferrer'}>
                                <BsDiscord />
                            </a>
                            <a href="https://github.com/Hulk-Cars" target={'_blank'} rel={'noreferrer'}>
                                <BsGithub />
                            </a>
                            <a href="https://www.instagram.com/hulk_cars_off" target={'_blank'} rel={'noreferrer'}>
                                <BsInstagram />
                            </a>
                            <a href="https://linktr.ee/hulkcars" target={'_blank'} rel={'noreferrer'}>
                                <SiLinktree />
                            </a>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={12} xs={12} className="footer-links">
                        <p className="title">Additional Links</p>
                        {
                            FooterLinks.map((link, index) => {
                                return (
                                    <small className="link" key={index}><a href={`#${link.redirect}`}>{link.name}</a></small>
                                )
                            })
                        }
                    </Col>
                    <Col lg={3} md={3} sm={12} xs={12} className="footer-documents">
                        <p className="title">Documents</p>
                        {
                            FooterDocuments.map((document, index) => {
                                return (
                                    <small className="link" key={index}><a href={document.redirect || '/'} target={'_blank'} rel={'noreferrer'}>{document.name}</a></small>
                                )
                            })
                        }
                        <div className="contact-us">
                            <h5>Contact us</h5>
                            <small>You can contact us at</small>
                            <a href={'mailto:contact@hulkcars.com'} className="contact-mail">contact@hulkcars.com</a>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={12} xs={12} className="footer-subscribe">
                        <p className="title">Subscribe</p>
                        <p className="desc">Will send you monthly updates.</p>
                        <Form.Control type="email" placeholder="Your e-mail..." />
                    </Col>
                </Row>
            </Container>
            <div className="footer-bar">
                <Container>
                    <Row>
                        <Col className="d-flex justify-content-center align-items-center">
                            <small>© 2024 <small className="text-light">Hulk Cars</small>, All Rights Reserved</small>
                            {/*<small><Link to="/">Privacy Policy Terms & Conditions</Link></small>*/}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Footer;
